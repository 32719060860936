define(
    'mixin',[],function() {
        return {
            load: function(name, req, onLoad, config) {
                var rArr = /^\[([^\]]+)\]$/,
                    trimSpaces = /[\s,]+/,
                    modules = name.replace(rArr, '$1').split(trimSpaces);

                if (config.isBuild) {
                    req(modules, function() {
                        onLoad(null);
                    });
                }
                else {
                    req(modules, function() {
                        var argsArr = Array.prototype.slice.call(arguments),
                            lastArgument = argsArr.pop();

                        onLoad(lastArgument);
                    });
                }
            }
        }
    }
);
