define(
    'js/lib/owo-drange-picker/date-range-conf',
    [],function() {
        var dateRangeConf = function() {
            return {
                defaults: {
                    autoClose: false,
                    format: 'YYYY-MM-DD',
                    separator: ' to ',
                    language: 'auto',
                    customTopBar: '',
                    startOfWeek: 'sunday',// or monday
                    getValue: null,// can be overrided by function (get/set elem from dom)
                    setValue: null,// can be overrided by function (get/set elem from dom)
                    useSpanOrDiv: false,
                    startDate: false,
                    endDate: false,
                    time: {
                        enabled: false
                    },
                    minDays: 0,
                    maxDays: 0,
                    applyBtnVal: 'update',
                    closeBtnVal: 'close',
                    showShortcuts: false,
                    // customValueLabel: 'Dynamic Ranges',
                    // showCustomValues: true,
                    customTopBarFromFooter: true,
                    shortcutCustomName: 'default-time-ranges',
                    shortcuts: {},
                    defaultCustomDateRange: '',
                    customShortcuts : {},
                    inline:false,
                    container:'body',
                    alwaysOpen:false,
                    singleDate:false,
                    lookBehind: false,
                    batchMode: false,
                    duration: 200,
                    stickyMonths: false,
                    dayDivAttrs: [],
                    dayTdAttrs: [],
                    selectForward: false,
                    selectBackward: false,
                    applyBtnClass: '',
                    singleMonth: 'auto',
                    showTopbar: true,
                    swapTime: false,
                    showWeekNumbers: false,
                    customOpenAnimation: null,
                    customCloseAnimation: null,
                    hoveringTooltip: function(days, startTime, hoveringTime, lang) {
                        return days > 1 ? days + ' ' + lang('days') : '';
                    },
                    getWeekNumber: function(date) {//date will be the first day of a week
                        return moment(date).format('w');
                    }
                },
                custom: {
                    useSpanOrDiv: true,
                    defaultCustomDateRange: 'last7days',
                    customShortcuts : {
                        last7days: {
                            className: 'custom-shortcut',
                            label: 'last-7-days',
                            dates : function() {
                                var today = new Date();
                                var yesterday = new Date(today - 86400000);
                                var sevenDaysAgo = new Date(today - 86400000 * 7);
                                return [ sevenDaysAgo, yesterday ];
                            }
                        },
                        last30days: {
                            className: 'custom-shortcut',
                            label: 'last-30-days',
                            dates : function() {
                                var today = new Date();
                                var yesterday = new Date(today - 86400000);
                                var thirtyDaysAgo = new Date(today - 86400000 * 30);
                                return [ thirtyDaysAgo, yesterday ];
                            }
                        },
                        last90days: {
                            className: 'custom-shortcut',
                            label: 'last-90-days',
                            dates : function() {
                                var today = new Date();
                                var yesterday = new Date(today - 86400000);
                                var ninetyDaysAgo = new Date(today - 86400000 * 90);
                                return [ ninetyDaysAgo, yesterday ];
                            }
                        },
                        lastYear: {
                            className: 'custom-shortcut',
                            label: 'last-year',
                            dates : function() {
                                var today = new Date();
                                var yesterday = new Date(today - 86400000);
                                var yearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
                                return [ yearAgo, yesterday ];
                            }
                        }
                    },
                    shortcuts: {
                        // 'prev-days': {
                        //     value: [1, 3, 5, 7],
                        //     label: 'past',
                        //     prefix: 'day,-',
                        //     className: 'prev-days'
                        // },
                        // 'next-days': {
                        //     value: [3, 5, 7],
                        //     label: 'following',
                        //     prefix: 'day,',
                        //     className: 'next-days'
                        // },
                        // 'prev' : {
                        //     value: ['week', 'month', 'year'],
                        //     label: 'previous',
                        //     prefix: 'prev,',
                        //     className: 'prev-buttons'
                        // },
                        // 'next' : {
                        //     value: ['week', 'month', 'year'],
                        //     label: 'next',
                        //     prefix: 'next,',
                        //     className: 'next-buttons'
                        // },
                    }
                }
            };
        };

        dateRangeConf.moduleName = 'dateRangeConf';
        dateRangeConf.toExecute = true;

        return dateRangeConf;
    }
);
