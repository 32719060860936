define(
    'dateRangePickerLanguages',
    [],function() {
        return {
            'default':  //default language: English
            {
                'selected': 'Selected:',
                'day': 'Day',
                'days': 'Days',
                'apply': 'Apply',
                'close': 'Close',
                'update': 'Update',
                'week-1' : 'mo',
                'week-2' : 'tu',
                'week-3' : 'we',
                'week-4' : 'th',
                'week-5' : 'fr',
                'week-6' : 'sa',
                'week-7' : 'su',
                'week-number': 'W',
                'month-name': ['january','february','march','april','may','june','july','august','september','october','november','december'],
                'shortcuts' : 'Shortcuts',
                'default-time-ranges': 'Default time ranges:',
                'custom-values': 'Custom Values',
                'past': 'Past',
                'following': 'Following',
                'previous' : 'Previous',
                'prev-week' : 'Week',
                'prev-month' : 'Month',
                'prev-year' : 'Year',
                'next':'Next',
                'next-week': 'Week',
                'next-month': 'Month',
                'next-year': 'Year',
                'last-7-days': 'Last 7 days',
                'last-30-days': 'Last 30 days',
                'last-90-days': 'Last 90 days',
                'last-year': 'Last year',
                'less-than' : 'Date range should not be more than %d days',
                'more-than' : 'Date range should not be less than %d days',
                'default-more' : 'Please select a date range longer than %d days',
                'default-single' : 'Please select a date',
                'default-less' : 'Please select a date range less than %d days',
                'default-range' : 'Please select a date range between %d and %d days',
                'default-default': 'Please select a date range',
                'time':'Time',
                'hour':'Hour',
                'minute':'Minute'
            },
            'az':
            {
                'selected': 'Seçildi:',
                'day':' gün',
                'days': ' gün',
                'apply': 'tətbiq',
                'week-1' : '1',
                'week-2' : '2',
                'week-3' : '3',
                'week-4' : '4',
                'week-5' : '5',
                'week-6' : '6',
                'week-7' : '7',
                'month-name': ['yanvar','fevral','mart','aprel','may','iyun','iyul','avqust','sentyabr','oktyabr','noyabr','dekabr'],
                'shortcuts' : 'Qısayollar',
                'past': 'Keçmiş',
                'following':'Növbəti',
                'previous' : '&nbsp;&nbsp;&nbsp;',
                'prev-week' : 'Öncəki həftə',
                'prev-month' : 'Öncəki ay',
                'prev-year' : 'Öncəki il',
                'next': '&nbsp;&nbsp;&nbsp;',
                'next-week':'Növbəti həftə',
                'next-month':'Növbəti ay',
                'next-year':'Növbəti il',
                'less-than' : 'Tarix aralığı %d gündən çox olmamalıdır',
                'more-than' : 'Tarix aralığı %d gündən az olmamalıdır',
                'default-more' : '%d gündən çox bir tarix seçin',
                'default-single' : 'Tarix seçin',
                'default-less' : '%d gündən az bir tarix seçin',
                'default-range' : '%d və %d gün aralığında tarixlər seçin',
                'default-default': 'Tarix aralığı seçin'
            },
            'cn':  //simplified chinese
            {
                'selected': '已选择:',
                'day':'天',
                'days': '天',
                'apply': '确定',
                'week-1' : '一',
                'week-2' : '二',
                'week-3' : '三',
                'week-4' : '四',
                'week-5' : '五',
                'week-6' : '六',
                'week-7' : '日',
                'week-number': '周',
                'month-name': ['一月','二月','三月','四月','五月','六月','七月','八月','九月','十月','十一月','十二月'],
                'shortcuts' : '快捷选择',
                'past': '过去',
                'following':'将来',
                'previous' : '&nbsp;&nbsp;&nbsp;',
                'prev-week' : '上周',
                'prev-month' : '上个月',
                'prev-year' : '去年',
                'next': '&nbsp;&nbsp;&nbsp;',
                'next-week':'下周',
                'next-month':'下个月',
                'next-year':'明年',
                'less-than' : '所选日期范围不能大于%d天',
                'more-than' : '所选日期范围不能小于%d天',
                'default-more' : '请选择大于%d天的日期范围',
                'default-less' : '请选择小于%d天的日期范围',
                'default-range' : '请选择%d天到%d天的日期范围',
                'default-single':'请选择一个日期',
                'default-default': '请选择一个日期范围',
                'time':'时间',
                'hour':'小时',
                'minute':'分钟'
            },
            'cz':
            {
                'selected': 'Vybráno:',
                'day':'Den',
                'days': 'Dny',
                'apply': 'Zavřít',
                'week-1' : 'po',
                'week-2' : 'út',
                'week-3' : 'st',
                'week-4' : 'čt',
                'week-5' : 'pá',
                'week-6' : 'so',
                'week-7' : 'ne',
                'month-name': ['leden','únor','březen','duben','květen','červen','červenec','srpen','září','říjen','listopad','prosinec'],
                'shortcuts' : 'Zkratky',
                'past': 'po',
                'following':'následující',
                'previous' : 'předchozí',
                'prev-week' : 'týden',
                'prev-month' : 'měsíc',
                'prev-year' : 'rok',
                'next':'další',
                'next-week':'týden',
                'next-month':'měsíc',
                'next-year':'rok',
                'less-than' : 'Rozsah data by neměl být větší než %d dnů',
                'more-than' : 'Rozsah data by neměl být menší než %d dnů',
                'default-more' : 'Prosím zvolte rozsah data větší než %d dnů',
                'default-single' : 'Prosím zvolte datum',
                'default-less' : 'Prosím zvolte rozsah data menší než %d dnů',
                'default-range' : 'Prosím zvolte rozsah data mezi %d a %d dny',
                'default-default': 'Prosím zvolte rozsah data'
            },
            'de':
            {
                'selected': 'Auswahl:',
                'day':'Tag',
                'days': 'Tage',
                'apply': 'Schließen',
                'week-1' : 'mo',
                'week-2' : 'di',
                'week-3' : 'mi',
                'week-4' : 'do',
                'week-5' : 'fr',
                'week-6' : 'sa',
                'week-7' : 'so',
                'month-name': ['januar','februar','märz','april','mai','juni','juli','august','september','oktober','november','dezember'],
                'shortcuts' : 'Schnellwahl',
                'past': 'Vorherige',
                'following':'Folgende',
                'previous' : 'Vorherige',
                'prev-week' : 'Woche',
                'prev-month' : 'Monat',
                'prev-year' : 'Jahr',
                'next':'Nächste',
                'next-week':'Woche',
                'next-month':'Monat',
                'next-year':'Jahr',
                'less-than' : 'Datumsbereich darf nicht größer sein als %d Tage',
                'more-than' : 'Datumsbereich darf nicht kleiner sein als %d Tage',
                'default-more' : 'Bitte mindestens %d Tage auswählen',
                'default-single' : 'Bitte ein Datum auswählen',
                'default-less' : 'Bitte weniger als %d Tage auswählen',
                'default-range' : 'Bitte einen Datumsbereich zwischen %d und %d Tagen auswählen',
                'default-default': 'Bitte ein Start- und Enddatum auswählen',
                'Time': 'Zeit',
                'hour': 'Stunde',
                'minute': 'Minute',
            },
            'es':
            {
                'selected': 'Seleccionado:',
                'day':'Dia',
                'days': 'Dias',
                'apply': 'Cerrar',
                'week-1' : 'lu',
                'week-2' : 'ma',
                'week-3' : 'mi',
                'week-4' : 'ju',
                'week-5' : 'vi',
                'week-6' : 'sa',
                'week-7' : 'do',
                'month-name': ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'],
                'shortcuts' : 'Accesos directos',
                'past': 'Pasado',
                'following':'Siguiente',
                'previous' : 'Anterior',
                'prev-week' : 'Semana',
                'prev-month' : 'Mes',
                'prev-year' : 'Año',
                'next':'Siguiente',
                'next-week':'Semana',
                'next-month':'Mes',
                'next-year':'Año',
                'less-than' : 'El rango no deberia ser mayor de %d dias',
                'more-than' : 'El rango no deberia ser menor de %d dias',
                'default-more' : 'Por favor selecciona un rango mayor a %d dias',
                'default-single' : 'Por favor selecciona un dia',
                'default-less' : 'Por favor selecciona un rango menor a %d dias',
                'default-range' : 'Por favor selecciona un rango entre %d y %d dias',
                'default-default': 'Por favor selecciona un rango de fechas.'
            },
            'fr':
            {
                'selected': 'Sélection:',
                'day':'Jour',
                'days': 'Jours',
                'apply': 'Fermer',
                'week-1' : 'lu',
                'week-2' : 'ma',
                'week-3' : 'me',
                'week-4' : 'je',
                'week-5' : 've',
                'week-6' : 'sa',
                'week-7' : 'di',
                'month-name': ['janvier','février','mars','avril','mai','juin','juillet','août','septembre','octobre','novembre','décembre'],
                'shortcuts' : 'Raccourcis',
                'past': 'Passé',
                'following':'Suivant',
                'previous' : 'Précédent',
                'prev-week' : 'Semaine',
                'prev-month' : 'Mois',
                'prev-year' : 'Année',
                'next':'Suivant',
                'next-week':'Semaine',
                'next-month':'Mois',
                'next-year':'Année',
                'less-than' : 'L\'intervalle ne doit pas être supérieure à %d jours',
                'more-than' : 'L\'intervalle ne doit pas être inférieure à %d jours',
                'default-more' : 'Merci de choisir une intervalle supérieure à %d jours',
                'default-single' : 'Merci de choisir une date',
                'default-less' : 'Merci de choisir une intervalle inférieure %d jours',
                'default-range' : 'Merci de choisir une intervalle comprise entre %d et %d jours',
                'default-default': 'Merci de choisir une date'
            },
            'hu':
            {
                'selected': 'Kiválasztva:',
                'day':'Nap',
                'days': 'Nap',
                'apply': 'Ok',
                'week-1' : 'h',
                'week-2' : 'k',
                'week-3' : 'sz',
                'week-4' : 'cs',
                'week-5' : 'p',
                'week-6' : 'sz',
                'week-7' : 'v',
                'month-name': ['január','február','március','április','május','június','július','augusztus','szeptember','október','november','december'],
                'shortcuts' : 'Gyorsválasztó',
                'past': 'Múlt',
                'following':'Következő',
                'previous' : 'Előző',
                'prev-week' : 'Hét',
                'prev-month' : 'Hónap',
                'prev-year' : 'Év',
                'next':'Következő',
                'next-week':'Hét',
                'next-month':'Hónap',
                'next-year':'Év',
                'less-than' : 'A kiválasztás nem lehet több %d napnál',
                'more-than' : 'A kiválasztás nem lehet több %d napnál',
                'default-more' : 'Válassz ki egy időszakot ami hosszabb mint %d nap',
                'default-single' : 'Válassz egy napot',
                'default-less' : 'Válassz ki egy időszakot ami rövidebb mint %d nap',
                'default-range' : 'Válassz ki egy %d - %d nap hosszú időszakot',
                'default-default': 'Válassz ki egy időszakot'
            },
            'it':
            {
                'selected': 'Selezionati:',
                'day':'Giorno',
                'days': 'Giorni',
                'apply': 'Chiudi',
                'week-1' : 'lu',
                'week-2' : 'ma',
                'week-3' : 'me',
                'week-4' : 'gi',
                'week-5' : 've',
                'week-6' : 'sa',
                'week-7' : 'do',
                'month-name': ['gennaio','febbraio','marzo','aprile','maggio','giugno','luglio','agosto','settembre','ottobre','novembre','dicembre'],
                'shortcuts' : 'Scorciatoie',
                'past': 'Scorso',
                'following':'Successivo',
                'previous' : 'Precedente',
                'prev-week' : 'Settimana',
                'prev-month' : 'Mese',
                'prev-year' : 'Anno',
                'next':'Prossimo',
                'next-week':'Settimana',
                'next-month':'Mese',
                'next-year':'Anno',
                'less-than' : 'L\'intervallo non dev\'essere maggiore di %d giorni',
                'more-than' : 'L\'intervallo non dev\'essere minore di %d giorni',
                'default-more' : 'Seleziona un intervallo maggiore di %d giorni',
                'default-single' : 'Seleziona una data',
                'default-less' : 'Seleziona un intervallo minore di %d giorni',
                'default-range' : 'Seleziona un intervallo compreso tra i %d e i %d giorni',
                'default-default': 'Seleziona un intervallo di date'
            },
            'no':
            {
                'selected': 'Valgt:',
                'day':'Dag',
                'days': 'Dager',
                'apply': 'Lukk',
                'week-1' : 'ma',
                'week-2' : 'ti',
                'week-3' : 'on',
                'week-4' : 'to',
                'week-5' : 'fr',
                'week-6' : 'lø',
                'week-7' : 'sø',
                'month-name': ['januar','februar','mars','april','mai','juni','juli','august','september','oktober','november','desember'],
                'shortcuts' : 'Snarveier',
                'custom-values': 'Egendefinerte Verdier',
                'past': 'Over', // Not quite sure about the context of this one
                'following':'Følger',
                'previous' : 'Forrige',
                'prev-week' : 'Uke',
                'prev-month' : 'Måned',
                'prev-year' : 'År',
                'next':'Neste',
                'next-week':'Uke',
                'next-month':'Måned',
                'next-year':'År',
                'less-than' : 'Datoperioden skal ikkje være lengre enn %d dager',
                'more-than' : 'Datoperioden skal ikkje være kortere enn %d dager',
                'default-more' : 'Vennligst velg ein datoperiode lengre enn %d dager',
                'default-single' : 'Vennligst velg ein dato',
                'default-less' : 'Vennligst velg ein datoperiode mindre enn %d dager',
                'default-range' : 'Vennligst velg ein datoperiode mellom %d og %d dager',
                'default-default': 'Vennligst velg ein datoperiode',
                'time':'Tid',
                'hour':'Time',
                'minute':'Minutter'
            },
            'nl':
            {
                'selected': 'Geselecteerd:',
                'day':'Dag',
                'days': 'Dagen',
                'apply': 'Ok',
                'week-1' : 'ma',
                'week-2' : 'di',
                'week-3' : 'wo',
                'week-4' : 'do',
                'week-5' : 'vr',
                'week-6' : 'za',
                'week-7' : 'zo',
                'month-name': ['januari','februari','maart','april','mei','juni','juli','augustus','september','october','november','december'],
                'shortcuts' : 'Snelkoppelingen',
                'custom-values': 'Aangepaste waarden',
                'past': 'Verleden',
                'following':'Komend',
                'previous' : 'Vorige',
                'prev-week' : 'Week',
                'prev-month' : 'Maand',
                'prev-year' : 'Jaar',
                'next':'Volgende',
                'next-week':'Week',
                'next-month':'Maand',
                'next-year':'Jaar',
                'less-than' : 'Interval moet langer dan %d dagen zijn',
                'more-than' : 'Interval mag niet minder dan %d dagen zijn',
                'default-more' : 'Selecteer een interval langer dan %dagen',
                'default-single' : 'Selecteer een datum',
                'default-less' : 'Selecteer een interval minder dan %d dagen',
                'default-range' : 'Selecteer een interval tussen %d en %d dagen',
                'default-default': 'Selecteer een interval',
                'time':'Tijd',
                'hour':'Uur',
                'minute':'Minuut'
            },
            'ru':
            {
                'selected': 'Выбрано:',
                'day': 'День',
                'days': 'Дней',
                'apply': 'Закрыть',
                'week-1': 'пн',
                'week-2': 'вт',
                'week-3': 'ср',
                'week-4': 'чт',
                'week-5': 'пт',
                'week-6': 'сб',
                'week-7': 'вс',
                'month-name': ['январь','февраль','март','апрель','май','июнь','июль','август','сентябрь','октябрь','ноябрь','декабрь'],
                'shortcuts': 'Быстрый выбор',
                'past': 'Прошедшие',
                'following': 'Следующие',
                'previous': '&nbsp;&nbsp;&nbsp;',
                'prev-week': 'Неделя',
                'prev-month': 'Месяц',
                'prev-year': 'Год',
                'next': '&nbsp;&nbsp;&nbsp;',
                'next-week': 'Неделя',
                'next-month': 'Месяц',
                'next-year': 'Год',
                'less-than': 'Диапазон не может быть больше %d дней',
                'more-than': 'Диапазон не может быть меньше %d дней',
                'default-more': 'Пожалуйста выберите диапазон больше %d дней',
                'default-single': 'Пожалуйста выберите дату',
                'default-less': 'Пожалуйста выберите диапазон меньше %d дней',
                'default-range': 'Пожалуйста выберите диапазон между %d и %d днями',
                'default-default': 'Пожалуйста выберите диапазон'
            },
            'pl':
            {
                'selected': 'Wybrany:',
                'day':'Dzień',
                'days': 'Dni',
                'apply': 'Zamknij',
                'week-1' : 'pon',
                'week-2' : 'wt',
                'week-3' : 'śr',
                'week-4' : 'czw',
                'week-5' : 'pt',
                'week-6' : 'so',
                'week-7' : 'nd',
                'month-name': ['styczeń','luty','marzec','kwiecień','maj','czerwiec','lipiec','sierpień','wrzesień','październik','listopad','grudzień'],
                'shortcuts' : 'Skróty',
                'custom-values': 'Niestandardowe wartości',
                'past': 'Przeszłe',
                'following':'Następne',
                'previous' : 'Poprzednie',
                'prev-week' : 'tydzień',
                'prev-month' : 'miesiąc',
                'prev-year' : 'rok',
                'next':'Następny',
                'next-week':'tydzień',
                'next-month':'miesiąc',
                'next-year':'rok',
                'less-than' : 'Okres nie powinien być dłuższy niż %d dni',
                'more-than' : 'Okres nie powinien być krótszy niż  %d ni',
                'default-more' : 'Wybierz okres dłuższy niż %d dni',
                'default-single' : 'Wybierz datę',
                'default-less' : 'Wybierz okres krótszy niż %d dni',
                'default-range' : 'Wybierz okres trwający od %d do %d dni',
                'default-default': 'Wybierz okres',
                'time':'Czas',
                'hour':'Godzina',
                'minute':'Minuta'
            },
            'se':
            {
                'selected': 'Vald:',
                'day':'dag',
                'days': 'dagar',
                'apply': 'godkänn',
                'week-1' : 'ma',
                'week-2' : 'ti',
                'week-3' : 'on',
                'week-4' : 'to',
                'week-5' : 'fr',
                'week-6' : 'lö',
                'week-7' : 'sö',
                'month-name': ['januari','februari','mars','april','maj','juni','juli','augusti','september','oktober','november','december'],
                'shortcuts' : 'genvägar',
                'custom-values': 'Anpassade värden',
                'past': 'över',
                'following':'följande',
                'previous' : 'förra',
                'prev-week' : 'vecka',
                'prev-month' : 'månad',
                'prev-year' : 'år',
                'next':'nästa',
                'next-week':'vecka',
                'next-month':'måned',
                'next-year':'år',
                'less-than' : 'Datumintervall bör inte vara mindre än %d dagar',
                'more-than' : 'Datumintervall bör inte vara mer än %d dagar',
                'default-more' : 'Välj ett datumintervall längre än %d dagar',
                'default-single' : 'Välj ett datum',
                'default-less' : 'Välj ett datumintervall mindre än %d dagar',
                'default-range' : 'Välj ett datumintervall mellan %d och %d dagar',
                'default-default': 'Välj ett datumintervall',
                'time':'tid',
                'hour':'timme',
                'minute':'minut'
            }
        };
    }
);
