define(
    'bootstrap',
    [
        'jquery',
        'lib/jquery-plugins/bootstrap/affix',
        'lib/jquery-plugins/bootstrap/alert',
        'lib/jquery-plugins/bootstrap/button',
        'lib/jquery-plugins/bootstrap/carousel',
        'lib/jquery-plugins/bootstrap/collapse',
        'lib/jquery-plugins/bootstrap/dropdown',
        'lib/jquery-plugins/bootstrap/modal',
        'lib/jquery-plugins/bootstrap/popover',
        'lib/jquery-plugins/bootstrap/scrollspy',
        'lib/jquery-plugins/bootstrap/tab',
        'lib/jquery-plugins/bootstrap/tooltip',
        'lib/jquery-plugins/bootstrap/transition'
    ],
    function($) {
        return $;
    }
);
