/* ========================================================================
 * Bootstrap: collapse.js v3.1.1
 * http://getbootstrap.com/javascript/#collapse
 * ========================================================================
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

define(
	'lib/jquery-plugins/bootstrap/collapse',
    [
        'jquery',
        'jquery-ui'
    ],
    function($) {
        'use strict';

        // COLLAPSE PUBLIC CLASS DEFINITION
        // ================================

        var Collapse = function(element, options) {
            this.$element = $(element)
            this.options = $.extend({}, Collapse.DEFAULTS, options)
            this.transitioning = null

            if (this.options.parent) this.$parent = $(this.options.parent)
            if (this.options.toggle) this.toggle()
        }

        Collapse.DEFAULTS = {
            toggle: true
        }

        Collapse.prototype.dimension = function() {
            var hasWidth = this.$element.hasClass('width')
            return hasWidth ? 'width' : 'height'
        }

        Collapse.prototype.show = function() {
            if (this.transitioning || this.$element.hasClass('in')) return

            var startEvent = $.Event('show.bs.collapse')
            this.$element.trigger(startEvent)
            if (startEvent.isDefaultPrevented()) return

            var actives = this.$parent && this.$parent.find('> .panel > .in')

            if (actives && actives.length) {
                var hasData = actives.data('bs.collapse')
                if (hasData && hasData.transitioning) return
                actives.collapse('hide')
                hasData || actives.data('bs.collapse', null)
            }

            var dimension = this.dimension()

            this.$element
                .removeClass('collapse')
                .addClass('collapsing')
                [dimension](0)

            this.transitioning = 1

            var complete = function() {
                this.$element
                    .removeClass('collapsing')
                    .addClass('collapse in')
                    [dimension]('auto')
                this.transitioning = 0
                this.$element.trigger('shown.bs.collapse')
            }

            if (!$.support.transition) return complete.call(this)

            var scrollSize = $.camelCase(['scroll', dimension].join('-'))

            this.$element
                .one($.support.transition.end, $.proxy(complete, this))
                .emulateTransitionEnd(350)
                [dimension](this.$element[0][scrollSize])
        }

        Collapse.prototype.hide = function() {
            if (this.transitioning || !this.$element.hasClass('in')) return

            var startEvent = $.Event('hide.bs.collapse')
            this.$element.trigger(startEvent)
            if (startEvent.isDefaultPrevented()) return

            var dimension = this.dimension()

            this.$element
                [dimension](this.$element[dimension]())
                [0].offsetHeight

            this.$element
                .addClass('collapsing')
                .removeClass('collapse')
                .removeClass('in')

            this.transitioning = 1

            var complete = function() {
                this.transitioning = 0
                this.$element
                    .trigger('hidden.bs.collapse')
                    .removeClass('collapsing')
                    .addClass('collapse')
            }

            if (!$.support.transition) return complete.call(this)

            this.$element
                [dimension](0)
                .one($.support.transition.end, $.proxy(complete, this))
                .emulateTransitionEnd(350)
        }

        Collapse.prototype.toggle = function() {
            this[this.$element.hasClass('in') ? 'hide' : 'show']()
        }


        // COLLAPSE PLUGIN DEFINITION
        // ==========================

        var old = $.fn.collapse

        $.fn.collapse = function(option) {
            return this.each(function() {
                var $this = $(this)
                var data = $this.data('bs.collapse')
                var options = $.extend({}, Collapse.DEFAULTS, $this.data(), typeof option == 'object' && option)

                if (!data && options.toggle && option == 'show') option = !option
                if (!data) $this.data('bs.collapse', (data = new Collapse(this, options)))
                if (typeof option == 'string') data[option]()
            })
        }

        $.fn.collapse.Constructor = Collapse


        // COLLAPSE NO CONFLICT
        // ====================

        $.fn.collapse.noConflict = function() {
            $.fn.collapse = old
            return this
        }


        // COLLAPSE DATA-API
        // =================

        $(document).on('click.bs.collapse.data-api', '[data-toggle=collapse]', function(e) {
            var $this = $(this), href
            var target = $this.attr('data-target')
                || e.preventDefault()
                || (href = $this.attr('href')) && href.replace(/.*(?=#[^\s]+$)/, '') //strip for ie7
            var $target = $(target)
            var data = $target.data('bs.collapse')
            var option = data ? 'toggle' : $this.data()
            var parent = $this.attr('data-parent')
            var $parent = parent && $(parent)

            if (!data || !data.transitioning) {
                if ($parent) $parent.find('[data-toggle=collapse][data-parent="' + parent + '"]').not($this).addClass('collapsed')
                $this[$target.hasClass('in') ? 'addClass' : 'removeClass']('collapsed')
            }

            $target.collapse(option)
        });

        return $;
    }
);

