define(
    'alertify',
    [
        'config',
        'lib/alertify/alertifyjs/alertify',
        'lib/alertify/config'
    ], function(Config, Alertify, alertifyConf) {

        Alertify.defaults = alertifyConf;
        Alertify.customDialogsList = [];
        Alertify.notifyDelays = {
            success: 5,
            error: 10
        };

        return Alertify;
    }
)
;
