/* ========================================================================
 * Bootstrap: popover.js v3.1.1
 * http://getbootstrap.com/javascript/#popovers
 * ========================================================================
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

define(
	'lib/jquery-plugins/bootstrap/propover',
    [
        'jquery',
        'jquery-ui'
    ],
    function($) {
        'use strict';

        // POPOVER PUBLIC CLASS DEFINITION
        // ===============================

        var Popover = function(element, options) {
            this.init('popover', element, options)
        }

        if (!$.fn.tooltip) throw new Error('Popover requires tooltip.js')

        Popover.DEFAULTS = $.extend({}, $.fn.tooltip.Constructor.DEFAULTS, {
            placement: 'right',
            trigger: 'click',
            content: '',
            template: '<div class="popover"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
        })


        // NOTE: POPOVER EXTENDS tooltip.js
        // ================================

        Popover.prototype = $.extend({}, $.fn.tooltip.Constructor.prototype)

        Popover.prototype.constructor = Popover

        Popover.prototype.getDefaults = function() {
            return Popover.DEFAULTS
        }

        Popover.prototype.setContent = function() {
            var $tip = this.tip()
            var title = this.getTitle()
            var content = this.getContent()

            $tip.find('.popover-title')[this.options.html ? 'html' : 'text'](title)
            $tip.find('.popover-content')[ // we use append for html objects to maintain js events
                this.options.html ? (typeof content == 'string' ? 'html' : 'append') : 'text'
                ](content)

            $tip.removeClass('fade top bottom left right in')

            // IE8 doesn't accept hiding via the `:empty` pseudo selector, we have to do
            // this manually by checking the contents.
            if (!$tip.find('.popover-title').html()) $tip.find('.popover-title').hide()
        }

        Popover.prototype.hasContent = function() {
            return this.getTitle() || this.getContent()
        }

        Popover.prototype.getContent = function() {
            var $e = this.$element
            var o = this.options

            return $e.attr('data-content')
                || (typeof o.content == 'function' ?
                o.content.call($e[0]) :
                o.content)
        }

        Popover.prototype.arrow = function() {
            return this.$arrow = this.$arrow || this.tip().find('.arrow')
        }

        Popover.prototype.tip = function() {
            if (!this.$tip) this.$tip = $(this.options.template)
            return this.$tip
        }


        // POPOVER PLUGIN DEFINITION
        // =========================

        var old = $.fn.popover

        $.fn.popover = function(option) {
            return this.each(function() {
                var $this = $(this)
                var data = $this.data('bs.popover')
                var options = typeof option == 'object' && option

                if (!data && option == 'destroy') return
                if (!data) $this.data('bs.popover', (data = new Popover(this, options)))
                if (typeof option == 'string') data[option]()
            })
        }

        $.fn.popover.Constructor = Popover


        // POPOVER NO CONFLICT
        // ===================

        $.fn.popover.noConflict = function() {
            $.fn.popover = old
            return this
        };

        return $;
    }
);

define("lib/jquery-plugins/bootstrap/popover", function(){});

