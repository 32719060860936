/* ========================================================================
 * Bootstrap: alert.js v3.1.1
 * http://getbootstrap.com/javascript/#alerts
 * ========================================================================
 * Copyright 2011-2014 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

define(
	'lib/jquery-plugins/bootstrap/alert',
    [
        'jquery',
        'jquery-ui'
    ],
    function($) {
        'use strict';

        // ALERT CLASS DEFINITION
        // ======================

        var dismiss = '[data-dismiss="alert"]'
        var Alert = function(el) {
            $(el).on('click', dismiss, this.close)
        }

        Alert.prototype.close = function(e) {
            var $this = $(this)
            var selector = $this.attr('data-target')

            if (!selector) {
                selector = $this.attr('href')
                selector = selector && selector.replace(/.*(?=#[^\s]*$)/, '') // strip for ie7
            }

            var $parent = $(selector)

            if (e) e.preventDefault()

            if (!$parent.length) {
                $parent = $this.hasClass('alert') ? $this : $this.parent()
            }

            $parent.trigger(e = $.Event('close.bs.alert'))

            if (e.isDefaultPrevented()) return

            $parent.removeClass('in')

            function removeElement() {
                $parent.trigger('closed.bs.alert').remove()
            }

            $.support.transition && $parent.hasClass('fade') ?
                $parent
                    .one($.support.transition.end, removeElement)
                    .emulateTransitionEnd(150) :
                removeElement()
        }


        // ALERT PLUGIN DEFINITION
        // =======================

        var old = $.fn.alert

        $.fn.alert = function(option) {
            return this.each(function() {
                var $this = $(this)
                var data = $this.data('bs.alert')

                if (!data) $this.data('bs.alert', (data = new Alert(this)))
                if (typeof option == 'string') data[option].call($this)
            })
        }

        $.fn.alert.Constructor = Alert


        // ALERT NO CONFLICT
        // =================

        $.fn.alert.noConflict = function() {
            $.fn.alert = old
            return this
        }


        // ALERT DATA-API
        // ==============

        $(document).on('click.bs.alert.data-api', dismiss, Alert.prototype.close)

        return $;
    }
);

